<template lang="html">
  <h1 class="Title" :class="{ TitleSpacer: spacer }">
    {{ pageData.title }}
    <span v-if="pageData.location">{{ pageData.location }}</span>
    <span v-if="pageData.timeframe">{{ pageData.timeframe }}</span>
    <div v-if="pageData.options.multilang == true" class="multilang">
      <button @click="$emit('switchLang')" :class="{ active: lang == '' }">
        EN
      </button>
      <button @click="$emit('switchLang')" :class="{ active: lang == '_fr' }">
        FR
      </button>
    </div>
  </h1>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    spacer: null,
    artworks: null,
    lang: null,
  },
  computed: {
    ...mapGetters(['pageData']),
  },
  mounted() {
    this.$store.state.loadedTitle = true;
  },
};
</script>

<style media="screen">
/* TITLE */
.Title {
  position: relative;
  display: block;
  padding-bottom: 72px;
  border-bottom: 1px solid black;
  font-size: 1.4rem;
  line-height: 2rem;
}

.TitleSpacer {
  margin-bottom: 12px;
}

.Title span {
  display: block;
  color: #333;
}

.multilang {
  position: absolute;
  top: -14px;
  right: 0;
  margin-top: 12px;
}

.multilang button {
  border: 1px solid #222929;
  background: white;
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
}

.multilang button:last-child {
  margin-right: 0;
}

.multilang button:hover {
  background: #222929;
  color: white;
}

.multilang .active {
  background: #222929;
  color: white;
}

@media only screen and (min-width: 600px) {
  .multilang {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 0;
  }

  /* TITLE */
  .Title {
    display: block;
    padding: 0;
    border-bottom: 0;
    margin-top: 36px;
    margin-bottom: 90px;
    font-size: 2.2rem;
    line-height: 3rem;
  }

  .Title span {
    color: #333;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .Title span:first-child {
    margin-top: 12px;
  }

  .TitleSpacer {
    border-bottom: none;
  }
}
</style>
