<template lang="html">
  <div v-if="pageData" class="Notfound">
    <Title spacer="" />

    <section class="Content">
      <Content :content="this.pageData.content" />
    </section>
  </div>
</template>

<script>
import Title from '@/components/Elements/Title.vue';
import Content from '@/components/Elements/Content.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Title,
    Content,
  },
  computed: {
    ...mapGetters(['pageData', 'pageList']),
  },
  methods: {
    ...mapActions(['fetchItems']),
  },
  metaInfo: {
    meta: [
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
};
</script>
