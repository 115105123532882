<template lang="html">
  <section class="Content" ref="block">
    <div v-for="block in getBlocks" v-if="getBlocks">
      <p
        v-if="block.type == 'paragraph'"
        v-html="block.data.text"
        :class="{ loading: observe }"
        class="block"
      />
      <ul v-if="block.type == 'list'">
        <li v-for="entry in block.data.items" v-html="entry"></li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      observer: false,
    };
  },
  props: {
    content: null,
    observe: null,
  },
  computed: {
    getBlocks() {
      if (this.isJSON(this.content)) {
        return JSON.parse(this.content)?.blocks;
      }
    },
  },
  methods: {
    isJSON(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    createObserver() {
      const item = this.$refs.block;

      this.observer = new IntersectionObserver(this.trigger, {
        threshold: 0.01,
      });

      this.observer.observe(item);
    },
    trigger(e) {
      if (e[0].isIntersecting) {
        const items = this.$refs.block.querySelectorAll('.loading');
        let timeout = 0;
        items.forEach(item => {
          timeout += 300;
          setTimeout(() => this.show(item), timeout);
        });
      }
    },
    show(item) {
      item.classList.add('loaded');
    },
  },
  mounted() {
    if (this.observe) {
      this.$nextTick(() => {
        this.createObserver();
      });
    }
  },
  beforeDestroy() {
    if (this.observe) this.observer.disconnect();
  },
};
</script>

<style scoped>
.loading {
  position: relative;
  opacity: 0;
  top: 80px;
  transition: all 0.8s;
}

.loaded {
  top: 0;
  opacity: 1;
}
</style>
